@import "react-slideshow-image/dist/styles.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body{
  overflow: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header{
  background-color: #fff;
  height: 60px;
  padding-left: 80px;
  padding-right: 80px;
  /* border: solid 1px black; */
  box-shadow: 0 5px 9px -7px;
}

.text-menu{
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 1em !important;
}

.text-btn-init{
  text-transform: none !important;
  font-weight: 500 !important;
}

.container-div{
  overflow-y: auto;
}

.card-start{
  width: 570px;
  margin: 0 auto;
  border-radius: 15px !important;
  background-color: #ffffffeb;
  padding: 20px 50px 50px 50px;
  /* margin-top: 7%; */
}

/* Tamaño del scroll */
::-webkit-scrollbar {
  width: 8px;
}

 /* Estilos barra (thumb) de scroll */
::-webkit-scrollbar-thumb {
  background: #0174DF;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

 /* Estilos track de scroll */
::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover, 
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.title-block{
  margin-top: 50px;
  font-size: 1.7em;
  font-weight: bold;
}

.subtext-principal{
  font-size: 1.2em;
  margin-top: 15px; 
  margin-bottom: 25px;
  padding-left: 27px; 
  padding-right: 27px;
}

.subtext-block1{
  width: 600px;
  margin: 0 auto;
  margin-top: 30px; 
  margin-bottom: 40px; 
  font-size: 0.8em;
  color: #9e9e9e
}

.circle-block1{
  width: 65px;
  height: 65px;
  background-color: #000;
  border-radius: 50px;
}

.title-item-block1{
  font-size: 0.8em;
  font-weight: bold;
}

.text-item-block1{
  font-size: 0.8em;
  color: #9e9e9e;
  margin-top: 10px;
}

.login-div{
  padding-top: 30px;
  width: 350px;
  margin: 0 auto;
}

.div-element{
  margin-bottom: 20px;
}

.div-footer{
  width: 600px;
}

.text-footer{
  color: #d3cece; 
  font-size: 0.8em;
  margin-bottom: 20px;
}
/**************/
@media screen and (min-width: 481px) and (max-width: 959px) {
  .header{
    padding-left: 5px;
    padding-right: 5px;
  }

  .text-menu{
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 0.7em !important;
  }
}

@media screen and (max-width: 480px) {
  .header{
    padding-left: 5px;
    padding-right: 5px;
  }

  .card-start{
    width: 66%;
  }

  .subtext-block1{
    width: 300px;
  }

  .div-footer{
    width: 300px;
  }
}
